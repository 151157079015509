:root {
    --color-brand-black: 2 2 2;
    --color-brand-jade: 2 131 135;
    --color-brand-teal: 12 169 142;
    --color-brand-green: 121 185 88;
    --color-brand-tan: 234 204 166;
    --color-brand-yellow: 246 171 57;
    --color-brand-orange: 232 115 15;
    --color-brand-amber: 205 72 35;
    --color-brand-gray: 89 89 89;
}

@tailwind base;
@tailwind components;
@tailwind utilities;